.catv-dash-pie-wrapper {
  padding: 1.5em;

  .pie-chart {
    flex: 1;
    text-align: center;
    position: relative;
    justify-content: center;

    svg {
      display: block;
      height: 100%;
      max-height: 266px;
    }
  }

  .pie-chart-legend {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // can't center if we give it scroll
    // justify-content: center;
    // scroll if too big legend
    overflow-y: auto;
    margin-left: 3em;
    // margin looks too big after certain width
    @media screen and (max-width: 1200px) {
      margin-left: 0.5em;
    }

    .pie-legend {
      display: table;
      cursor: pointer;

      .pie-indicator-wrapper {
        width: 20px;
        display: table-cell;
        vertical-align: middle;

        .pie-indicator {
          height: 14px;
          width: 14px;
          border-radius: 50%;
        }
      }

      .pie-label {
        display: table-cell;
        vertical-align: middle;
        color: #e0e0e3;
        font-variant-caps: all-small-caps;
        padding-bottom: 4px;
        font-size: 1.2em;
      }
    }
  }
}
