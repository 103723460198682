.watermark {
  position: fixed;
  bottom: 8px;
  left: 8px;
  width: 120px;
  pointer-events: none;
  .logo {
    width: 100%;
    height: auto;
  }
}
