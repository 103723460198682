@import "../../App/global";

.map-actionbar {
  position: absolute;
  top: 60px;
  z-index: 1;
  left: 10px;
  transition: left 0.2s linear;

  &.sidebar-open {
    // 386px ( sidebar width ) + 10px;
    left: 396px;
  }

  .icon-button {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: $secondary-main-color;
    box-shadow: #0000004d 0px 1px 4px -1px;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      background-color: darken($color: white, $amount: 7);
    }

    &.active {
      background-color: $secondary-main-color;
      color: $background-default-color;
    }
  }
}

.zoom-block {
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px !important;
  border-radius: 2px !important;
  transition: 0.2s all !important;
  pointer-events: none;
  display: inline-block;
  position: absolute;
  bottom: 1em;
  right: 3.5em;
  padding: 0.2em 0.5em;
  &:hover {
    background-color: darken($color: white, $amount: 7) !important;
  }
}
