$off-white: rgb(252, 252, 252);
$primary-font-color: #404040;
$dark-grey-color: #707070;
$success-color: #10c41c;
$error-color: #ff0000;
$primary-color: #3b42d9;

.auth-page {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("/assets/auth-bg@3x.jpg");
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  background-color: $off-white;

  @media screen and (max-width: 993px) {
    background-image: url("/assets/auth-bg@1x.jpg");
  }

  @media screen and (min-width: 1000px) and (max-width: 1900px) {
    background-image: url("/assets/auth-bg@2x.jpg");
  }

  .shadow {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(
      linear,
      right top,
      left top,
      color-stop(33%, #ffffff00),
      to(#00000033)
    );
    background: -o-linear-gradient(right, #ffffff00 33%, #00000033 100%);
    background: linear-gradient(270deg, #ffffff00 33%, #00000033 100%);
  }

  .login-page {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
  }

  .login-page-content {
    flex: 1;
    display: flex;
    justify-content: space-between;

    .heading {
      font-size: 3em;
      line-height: 1.6;
      letter-spacing: 0px;
      color: #fcfcfc;
      text-shadow: 0px 3px 18px #00000040;

      .store-link {
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5em;
        margin-top: 0.5em;

        img {
          width: 150px;

          &.store-google {
            width: 160px;
          }
        }
      }
    }

    .company-title {
      text-align: center;
      font-size: 1.6em;
      padding: 1em 1.9em;
      color: $primary-font-color;
    }

    .form-block {
      box-shadow: 0px 3px 16px 0px #0000004d;
      background-color: rgba(252, 252, 252, 0.75);
      border-radius: 0.8em;
      // for big screens
      width: 30em;
      margin-left: auto;
      text-align: center;

      .privacy-policy-link {
        text-align: center;
        padding: 0.4em;
        font-size: 0.8em;
        color: grey;

        .version {
          font-size: 1.1em;
        }

        a {
          color: grey !important;
        }
      }

      .form-group {
        input {
          background-color: $off-white;
        }
      }
      .form-inner-block {
        padding: 5em 0;
        display: inline-block;
        text-align: left;
        min-height: 22.3em;
        width: 60%;
      }

      .forgot-pass {
        margin-top: -1em;
        text-align: right;

        a {
          color: grey !important;
          font-size: 0.8em;
          text-decoration: underline;
          margin-top: -1em;
          position: relative;
        }
      }

      .btn {
        margin-bottom: 1em;
        margin-top: 3em;
      }

      .sub-title {
        max-width: 18em;
        margin: 0 auto;
        font-size: 0.86em;

        &.accent-text {
          color: $dark-grey-color;
        }

        &.success-text {
          color: $success-color;
          padding-bottom: 2.3em;
        }

        .extra-pad {
          padding-top: 4px;
          padding-bottom: 10px;
        }
      }

      .tiny-url {
        color: grey;
        font-size: 0.88em;

        a {
          color: $primary-color;
        }
      }
    }

    // for mobile and 5s
    @media screen and (max-width: 601px) {
      .heading {
        .store-link {
          img {
            width: 120px;

            &.store-google {
              width: 130px;
            }
          }
        }
      }

      .form-block {
        width: 96%;
        .form-inner-block {
          width: 82%;
        }
      }
    }

    @media screen and (max-width: 993px) {
      flex-direction: column;

      .heading {
        margin: 0 auto 2rem auto;

        .store-icons {
          text-align: center;
        }
      }

      .form-block {
        margin-right: auto;
      }
    }
  }

  @media screen and (max-width: 993px) {
    .shadow {
      background: -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(86%, #ffffff00),
        to(#00000033)
      );
      background: -o-linear-gradient(right, #ffffff00 86%, #00000033 100%);
      background: linear-gradient(270deg, #ffffff00 86%, #00000033 100%);
    }

    .login-page {
      width: 96%;

      .form-inner-block {
        width: 99%;
      }
    }
  }
}
