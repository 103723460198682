@import "../../App/global";

#region-page {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;

  .reg-content-wrapper {
    display: flex;
    height: calc(100% - 2em);
    width: 100%;

    .reg-pocket-list {
      flex: 2;
      border-right: 1px solid silver;
      overflow-y: auto;

      .reg-list-wrapper {
        padding: 1em 1em;

        .reg-list-header-pill {
          font-size: 1.2em;
          font-weight: bold;
          padding: 0.5em;
        }

        .reg-list-pill {
          font-size: 1em;
        }
      }
    }

    .reg-content {
      flex: 5;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .reg-map-container {
        position: relative;
        flex: 1;
      }
    }
  }
}

.reg-map-details {
  position: absolute;
  right: 4em;
  top: 1em;
  z-index: 2;

  .MuiCard-root {
    background-color: $secondary-main-color;
    color: white;

    .MuiButton-root {
      min-width: 9em;
    }
  }
}
