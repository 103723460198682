.planning-config-page {
  .planning-pill {
    color: #59666c;

    a {
      color: #59666c;
      text-decoration: unset;
    }
  }
}
