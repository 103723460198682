$prime-font-color: rgba(0, 0, 0, 0.57);

.ag-pagination-list {
  height: 100%;

  .ag-custom-pagination {
    height: 56px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-top: 1px solid #e2e2e2;

    .page-navigation-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 30px;

      .page-info {
        color: $prime-font-color;
        padding: 10px;
      }

      .page-navigation-actions {
        display: flex;
        justify-content: center;
        align-items: center;

        .action {
          padding: 10px;
          margin: 4px;
          cursor: pointer;
        }
      }
    }
  }
}
