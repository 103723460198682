@import "../../App/global";

#planning-page {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;

  .pl-sidebar-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;

    .pl-sidebar {
      flex: 2;
      border-right: 1px solid silver;
      overflow-y: auto;

      .pl-list-wrapper {
        padding: 0em 1em;
        height: 100%;
        display: flex;
        flex-direction: column;

        .pl-list-header-pill {
          font-size: 1.2em;
          font-weight: bold;
          padding: 0.5em;
        }

        .pl-list-tabs {
          flex: 0 0 72px;
        }

        .pl-list-tab-content {
          flex: 1;
        }

        .pl-list-pill {
          font-size: 1em;
        }

        // moved css to below so can access from another component
      }
    }

    .pl-content {
      flex: 5;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .pl-map-container {
        position: relative;
        flex: 1;

        .ticket-sidebar-wrapper {
          position: absolute;
          right: 2em;
          top: 8%;
          height: 80%;
          z-index: 5;
          width: 25%;
          min-width: 300px;

          .ticket-sidebar-heading {
            padding: 0.5em;
          }

          .ticket-sidebar-content {
            height: 100%;
            overflow-y: auto;
          }
        }
      }
    }

    .pl-sidebar-abs {
      height: inherit;
      width: 386px;
      position: absolute;
      left: -386px;
      top: 0;
      border-right: 1px solid silver;
      overflow-y: auto;
      flex: unset;
      z-index: 1;
      background-color: $background-default-color;
      transition: left 0.2s linear;

      &.show {
        left: 0;
      }
    }
  }
}

.pl-map-details {
  position: absolute;
  right: 20%;
  top: 1em;
  z-index: 2;

  .MuiCard-root {
    background-color: $secondary-main-color;
    color: white;

    .MuiButton-root {
      min-width: 9em;
    }
  }
}

.pl-add-element-item {
  position: relative;
  height: 100%;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 0.4em;
  border: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 2;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .pl-add-element-config-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 1.5em;
    width: 1.5em;
    z-index: 3;
  }
}

.pl-layer-icon-block {
  padding: 6px;
  // margin-left: 4px !important;
  // layer tab icon
  .icon-wrapper {
    width: 36px;
    height: 36px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.24);
    padding: 2px;
    img {
      width: auto;
      height: 100%;
    }
  }
}

// specific style for marker label
.gis-marker-label {
  // background-color: white;
  font-weight: 600;
  padding: 0.3em;
  border-radius: 0.2em;
  text-shadow: 0.1em 0.1em #0000004d;
}
