.report-table {
  table-layout: auto;
  width: 100%;
  border-spacing: 0px;
  border: 1px solid black;
  border-collapse: collapse;

  &.no-border {
    border: none;
  }

  &.no-side-border {
    border-left: none;
    border-right: none;
  }

  .section-title {
    text-align: center;
    padding: 6px 8px;
    font-weight: bold;

    &.left-t {
      text-align: left;
    }
  }

  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 0 6px;
    word-wrap: break-word;

    &.sr-col {
      width: 66px;
    }
  }

  .th-border {
    border-right: 1px solid black;

    &:last-of-type {
      border-right: none;
    }
  }
}

.report-table-border {
  border: 1px solid black;
}

.report-table-side-border {
  border-left: 1px solid black;
  border-right: 1px solid black;
}
