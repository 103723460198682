$primary-main-color: #1881bc;
$secondary-main-color: #b98919;
$background-default-color: #efefef;
$error-color: #e24c4b;
$warning-color: #ffc005;
$success-color: #4bae4f;

@use "~ag-grid-community/styles" as ag;
@include ag.grid-styles();

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
}

#layout {
  flex: 1;
  max-width: 100%;
  // full height - navbar height
  height: calc(100% - 64px);
}

.page-wrapper,
#map {
  width: 100%;
  height: 100%;
}

// react select overrided style
#rc-menu-portal,
.form-select__menu-portal {
  z-index: 1400 !important;
}

.form-select {
  .form-select__control {
    border-color: transparent !important;
    box-shadow: none;
    padding: 9px;

    .form-select__single-value {
      text-overflow: inherit;
      white-space: pre-wrap;
    }

    .form-select__multi-value {
      .form-select__multi-value__label {
        text-overflow: inherit;
        white-space: pre-wrap;
      }
    }
  }
  .form-select__menu {
    z-index: 2;
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.87);
      &.Mui-error {
        border-color: $error-color;
      }
    }
  }
  .MuiOutlinedInput-notchedOutline {
    text-align: left;
    position: absolute;
    bottom: 0;
    right: 0;
    top: -5px;
    left: 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);

    &.active {
      .legend {
        max-width: 100%;
      }
    }

    &.highlight {
      border-color: $primary-main-color;
      border-width: 2px;
    }

    &.Mui-error {
      border-color: $error-color;
    }

    .legend {
      float: unset;
      overflow: hidden;
      display: block;
      width: auto;
      padding: 0;
      height: 11px;
      font-size: 0.75em;
      visibility: hidden;
      max-width: 0.01px;
      -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
      transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
      white-space: nowrap;

      span {
        padding-left: 5px;
        padding-right: 5px;
        display: inline-block;
        opacity: 0;
        visibility: visible;
      }
    }
  }
}

.form-select-controller {
  .form-select-input-wrapper {
    padding: 0;
  }
}

.form-creatable-select {
  .form-creatable-select__control {
    border-color: transparent !important;
    box-shadow: none;
    padding: 9px;
  }
  .form-creatable-select__menu {
    z-index: 2;
  }
}

.form-creatable-select-controller {
  .form-creatable-select-input-wrapper {
    padding: 0;
  }
}

.coming-soon {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  .coming-soon-content {
    width: 100%;
    text-align: center;

    img {
      width: 500px;
      max-width: 40%;
    }
  }
}

.clickable {
  cursor: pointer;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100%;
}

.g-relative {
  position: relative;
}

.mt-8 {
  margin-top: 8px !important;
}

.text-underline:hover {
  text-decoration: underline;
}

img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: 100%;
  width: auto;
}

.no-click {
  pointer-events: none;
}

.change-bg-on-hover {
  transition: 0.2s all;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.leader-line {
  z-index: 100;
}

._2iv3M {
  top: calc(var(--clock-size) / 2) !important;
  width: calc(var(--clock-size) / 2) !important;
}
