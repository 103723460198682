#dash-task-list {
  padding: 1em;
  padding-bottom: 0em;
  height: 100%;
  width: 100%;

  .dtl-title {
    padding: 0.3em;
    border-radius: 0.5em;
    text-align: center;
    height: 2em;
  }

  .dtl-content-wrapper {
    // 2em for title, 1em for padding
    height: calc(100% - 3em);
    overflow: auto;
  }
}
